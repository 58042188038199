import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Message } from '@angular/compiler/src/i18n/i18n_ast.js';
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs';
import './../../assets/js/smtp.js'; //file path may change → 
declare let Email: any;


@Injectable({
  providedIn: 'root'
})


export class EmailService {
  constructor(private readonly httpClient: HttpClient) { }

  // getToken(){
  //     let token = this.httpClient.post<string>("https://localhost:5001/api/identity/token", {
  //         "email": "test",
  //         "password": "test"
  //       });

  // }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
  };
  // sendEmail(): Observable<boolean>{
  //     console.log("sending post to api");
  //     let response = this.httpClient.post<boolean>("https://localhost:5001/api/email",JSON.stringify(""),this.httpOptions );
  //     console.log(response);
  //     return response;
  // }

  sendEmail(mailAdress: string, tekst: string, naam: string) {
    Email.send({
      Host : "smtp.elasticemail.com",
      Username: "homie.gent@gmail.com",
      Password: "FE26D542D9EEC5BA8D6836172B3E72CAF990",
      To: 'homie.gent@gmail.com',
      From: "homie.gent@gmail.com",
      Subject: "WEBPAGE CONTACT VAN: " + naam,
      Body: "<p>Contact formulier ingevuld door:<br/><br/>Naam: " + naam + "<br/><br/>Email: " + mailAdress + "<br/><br/>Bericht:<br/><br/>" + tekst + "</p>",
    }).then(
      message => alert("Email is verzonden.")
    );
  }


}