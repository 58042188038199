import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies-policies',
  templateUrl: './cookies-policies.component.html',
  styleUrls: ['./cookies-policies.component.css']
})
export class CookiesPoliciesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
