import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './examples/Home/home.component';
import { UnderConstructionComponent } from './examples/under-construction/under-construction.component';
import { DisclaimerComponent } from './examples/disclaimer/disclaimer.component';
import { AlgemeneVoorwaardenComponent } from './examples/algemene-voorwaarden/algemene-voorwaarden.component';
import { CookiesPoliciesComponent } from './examples/cookies-policies/cookies-policies.component';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'portalCommingSoon', component: UnderConstructionComponent },
    { path: 'disclaimer', component: DisclaimerComponent },
    { path: 'algemeneVoorwaarden', component: AlgemeneVoorwaardenComponent },
    { path: 'cookiesPolicy', component: CookiesPoliciesComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
