import { Component, OnInit } from '@angular/core';
import { EmailService } from 'app/services/EmailService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  public alerts: Array<IAlert> = [];

  focus: any;
  focus1: any;

  divverhuurdersabonnement: boolean = false;
  divstarterspakket: boolean = false;
  divplaatsbeschrijving: boolean = false;
  divhuurschadeberekening: boolean = false;

  verhuurdersabonnementDiv() {

    this.navigateDetailsDiv();
    this.divverhuurdersabonnement = true;
    this.divstarterspakket = false;
    this.divhuurschadeberekening = false;
    this.divplaatsbeschrijving = false;
  }

  starterspakketDiv() {

    this.divstarterspakket = true;
    this.divverhuurdersabonnement = false;
    this.divhuurschadeberekening = false;
    this.divplaatsbeschrijving = false;
    this.navigateDetailsDiv();

  }

  plaatsbeschrijvingDiv() {

    this.divplaatsbeschrijving = true;
    this.divstarterspakket = false;
    this.divverhuurdersabonnement = false;
    this.divhuurschadeberekening = false;
    this.navigateDetailsDiv();

  }
  huurschadeberekeningDiv() {

    this.divhuurschadeberekening = true;
    this.divplaatsbeschrijving = false;
    this.divstarterspakket = false;
    this.divverhuurdersabonnement = false;
    this.navigateDetailsDiv();

  }

  CloseDetails() {
    this.divhuurschadeberekening = false;
    this.divplaatsbeschrijving = false;
    this.divstarterspakket = false;
    this.divverhuurdersabonnement = false;
    this.navigateDetails();
  }



  constructor(private readonly emailService: EmailService, private router: Router) { }

  ngOnInit() { }

  OnSubmit() {
    this.SendMail((<HTMLInputElement>document.getElementById("email")).value, (<HTMLInputElement>document.getElementById("bericht")).value,
      (<HTMLInputElement>document.getElementById("naam")).value);
    (<HTMLInputElement>document.getElementById("email")).value = "";
     (<HTMLInputElement>document.getElementById("bericht")).value = "",
      (<HTMLInputElement>document.getElementById("naam")).value = ""
  }

  SendMail(mailAdress: string, message: string, name: string) {
    var succesMessage = this.emailService.sendEmail(mailAdress, message, name);

    // this.alerts.push({ 
    //   id: 1,
    //   type: 'success',
    //   message: succesMessage})
  }

  public closeAlert(alert: IAlert) {
    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }

  OnSubmitVerhuurdersAbonnement() {
    window.open("https://portal.homie-gent.be/", '_blank');
    // this.router.navigate([`portalCommingSoon`]);
  }
  OnSubmitStarterspakket() {
    window.open("https://portal.homie-gent.be/", '_blank');
  }
  OnSubmitPlaatsbeschrijving() {
    window.open("https://portal.homie-gent.be/", '_blank');
  }
  OnSubmitHuurschadeberekening() {
    window.open("https://portal.homie-gent.be/", '_blank');
  }
  Portaal() {
    window.open("https://portal.homie-gent.be/", '_blank');
  }

  navigateDetails() {
    const id = 'prijsoverzicht';
    const yOffset = -50;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
    // document.getElementById('prijsoverzicht').scrollIntoView({ behavior: 'smooth', block: 'start' });

  }

  navigateDetailsDiv() {
    const id = 'detailsDiv';
    const yOffset = -100;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });

  }
}


export interface IAlert {
  id: number;
  type: string;
  message: string;
  icon?: string;
}