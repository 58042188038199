import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-algemene-voorwaarden',
  templateUrl: './algemene-voorwaarden.component.html',
  styleUrls: ['./algemene-voorwaarden.component.css']
})
export class AlgemeneVoorwaardenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
