import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HomeComponent } from './Home/home.component';
import { CountDownComponent } from './count-down/count-down.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { AlgemeneVoorwaardenComponent } from './algemene-voorwaarden/algemene-voorwaarden.component';
import { CookiesPoliciesComponent } from './cookies-policies/cookies-policies.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
    ],
    declarations: [
        HomeComponent,
        CountDownComponent,
        UnderConstructionComponent,
        DisclaimerComponent,
        AlgemeneVoorwaardenComponent,
        CookiesPoliciesComponent
    ]
})
export class ExamplesModule { }
